  #project-scroll-box{
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
      overflow: hidden;
      white-space:nowrap;
      overflow-x: scroll;
      text-align: center;
      height: 100%;
      width: 100%;
      margin: 0 0 0 0;
  }
  #project-list-scroll-box {
    /* position: relative;
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    /* overflow: hidden; */
    /* text-align: center;
    width: 100%;
    margin: 0; */ 

    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: start;
    flex-wrap: unset;
    white-space:nowrap;
    /* overflow: auto;            */
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-bottom: 0.5rem;
  }
  
 #project-title {
    position: static;
    right: 0;
    /* border-bottom: 0.25rem solid var(--green); */
    color: var(--orange);
    /* color: white; */
    text-align: center;
    padding: 0;
    margin: 0 0;
  }
  .project-list {
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-align: center;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    margin: 0 0.5rem;
    /* padding: auto; */
    width: 75vw;
    height: auto;
    /* overflow: hidden; */
    /* min-width: 10.0rem;
    max-width: 15.0rem; */
    cursor: pointer;
  }
  @media only screen and (max-width: 600px){
    .project-list {
      width: 75vw;
      max-height: 25vh;
    }
  }
  @media only screen and (min-width: 768px){
    .project-list {
      width: 35vw;
      max-height: 22vh;
    }
  }
  @media only screen and (min-width: 992px){
    .project-list {
      width: 20vw;
      max-height: 18vh;
    }
  }

  .project-list:hover {
    /* position: absolute; */
    z-index: 2;
    /* zoom: 1.5; */
    transform: scale(1.25);
  }
  .project-list-image {
    width: 80%;
    height: auto;
    text-align: center;
    object-fit: cover;
    object-position: top;

    margin-top: 1.5rem;
    margin-bottom: -20%;
    margin-left: auto;
    margin-right: auto;

    border: 0.15rem ridge var(--green);
    border-radius: 0.5rem;
  }
  @media only screen and (max-width: 1000px){
    .project-list-image {
      margin-top: 2.0rem;
      margin-bottom: 1.0rem;
    }
  }
  .project-list-title {
    position: absolute;
    left: auto;
    right: auto;
    top: 0;
    width: 100%;
    text-align: center;
    font-family: 'Piazzolla', serif;
    font-size: 1.3rem;
    color: var(--orange);
    background-color: rgba(0,0,0,0.7);
    border-bottom-right-radius: 1.0rem;
    border-bottom-left-radius: 1.0rem;
    padding: 0.2rem;
    /* opacity: 0.85; */
  }
  .project-list-text {
    position: absolute;
    bottom: -0.5rem;
    /* margin-bottom: -2.0rem; */
    /* margin-top: 1.0rem; */
    width: 100%;
    /* height: 100%; */
    white-space: pre-wrap;
    font-family: 'Raleway', sans-serif;
    font-size: 0.55rem;
    line-height: 0.8rem;
    letter-spacing: 0.15rem;
    text-decoration: none;
    color: white;
    padding: 0.35rem;
    border-radius: 0.3rem;
    background-color: rgba(0,0,0,0.7);
    /* opacity: 0.75; */
  }
  
/* Color bottom tabs: page selection  */
.carousel-indicators-box {
  /* z-index: 10; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0.5rem;
  /* margin-bottom: -2rem; */
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
}
.carousel-indicators {
  position: relative;
  display: block;
    background-color: var(--green);
    height: 1.0rem;
    width: 1.0rem;
    margin: auto 0.5rem;
    cursor: pointer;
}
.carousel-indicators-active {
  background-color: var(--orange);
}
.carousel-indicators-features {
  position: relative; 
  bottom: 1.25rem;
  display: block;
  background-color: none;
  height: 1.0rem;
  width: 1.0rem;
  margin: auto 0.5rem;
  color: var(--green); 
  font-size: 2.0rem;
  cursor: pointer;
}
.carousel-indicators-features-active {
  color: var(--orange); 
}
.carousel-title {
    z-index: 7;
    position: absolute;
    top:0;
    left: 0;
    /* bottom: 55vh; */
    /* width: 100%; */
    text-align: left;
    font-family: 'Piazzolla', serif;
    font-size: 1.5rem;
    color: var(--orange);
    background-color: black;
    border-bottom-right-radius: 1.0rem;
    /* border-bottom-left-radius: 1.0rem; */
    padding: 0.5rem;
}
.carousel-description-box {
  position: absolute;
  bottom:0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;    
}
.carousel-description {
  width: 70%;
  margin: 0 auto;
  padding: 0.40rem;
  padding-bottom: 2.0rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: black;
  
  font-family: 'Raleway', sans-serif;
  font-size: 0.8rem;
  line-height: 1.0rem;
  letter-spacing: 0.15rem;
  text-decoration: none;
  text-align: center;
  color: var(--orange);
  opacity: 0.85;
}
.carousel-box {
  display: block;
  position: relative;
  overflow: hidden;
  /* width:100%; */
  margin: 0;
}
.carousel-slide-animation {
  /* width: 100%;
  height: 100%; */
  animation-duration: 1s;
  animation-name: slideZoom;
}

@keyframes slideLeft {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}
@keyframes slideRight {
  from {
    margin-right: 100%;
  }
  to {
    margin-right: 0%;
  }
}
@keyframes slideUp {
  from {
    margin-bottom: -100%;
  }
  to {
    margin-bottom: 0%;
  }
}
@keyframes slideZoom {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.0);
  }
}

@media only screen and (min-width: 992px){
  .carousel-box {
   margin: 0 10%;
  }
  .carousel-title {
    left: 10%;
  }
}
.project-image {
  /* max-height: 50vh; */
  /* height: 100%; */
  /* max-height: 75vh; */
  /* width: 100%; */
  object-fit: contain;
  margin: auto;
  text-align: center;
}
.project-tech-title {
  position: static;
  right: 0;
  background-color: black;
  border-top: 0.25rem solid var(--green);
  border-bottom: 0.25rem solid var(--green);
  color: var(--orange);
  /* color: white; */
  text-align: center;
  padding: 0.5rem;
  margin: 1.0rem 0;
}
.project-tech {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.55rem;
  color: var(--green);
  background: var(--grey);
  font-family: 'Piazzolla', serif;
  font-weight: 700;
  /* width: 100%; */
}
.project-tech:hover {
  background: black;
}

/* Fix For Transition lag  */
.carousel-inner>.item{
  /*disables white flash*/
  -webkit-transition: -webkit-transform .5s ease-in-out;
  -o-transition: -o-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
}
.project-arrow {
  display: table;
  /* margin-top: 2.7rem; */
  position: absolute;
  top: 5%;
  bottom: 5%;
  height: 90%;
  /* margin: auto 0; */
  text-align: center;
  z-index: 5;
  width: 10vw;
  border: none;
  color: whitesmoke;
  font-size: 5.0rem;
  background-color: black;
  opacity: 0.5;
  cursor: pointer;
}
/* Remove default Button Outlining */
.project-arrow:active, .project-arrow:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}
.project-arrow:hover {
  opacity: 0.95;
  color: var(--orange);
}
.project-arrow-Left {
  left: 0;
  border-top-right-radius: 1.0rem;
  border-bottom-right-radius: 1.0rem;

}
.project-arrow-Right {
  right: 0;
  border-top-left-radius: 1.0rem;
  border-bottom-left-radius: 1.0rem;

}
.project-additional-header {
  color: whitesmoke;
  font-family: 'Piazzolla', serif;
  font-size: 2.0rem;
  letter-spacing: 0.35rem;
  text-decoration: none;
  text-align: center;
  margin: 2.0rem;
}
.project-additional-text {
  color: var(--orange);
  font-family: 'Raleway', sans-serif;
  font-size: 1.1rem;
  line-height: 1.3rem;
  letter-spacing: 0.18rem;
  text-decoration: none;
  text-align: left;
  margin: 1.3rem 0;
  margin-left: 25vw;
}
.project-scroll-arrow-Nav {
  display: table;
  position: absolute;
  text-align: center;
  z-index: 5;
  width: 7vw;
  height: 100%;
  border: none;
  border: none;
  color: whitesmoke;
  font-size: 5.0rem;
  background-color: black;
  opacity: 0.7;
  cursor: pointer;
}
/* Remove default Button Outlining */
.project-scroll-arrow-Nav:active, .project-scroll-arrow-Nav:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}
.project-scroll-arrow-Nav:hover {
  opacity: 0.95;
  color: var(--orange);
}
.project-scroll-arrow-Left {
  left: 0;
  border-top-right-radius: 1.0rem;
  border-bottom-right-radius: 1.0rem;

}
.project-scroll-arrow-Right {
  right: 0;
  border-top-left-radius: 1.0rem;
  border-bottom-left-radius: 1.0rem;

}
.carousel-demo-button {
  z-index: 6;
  position: absolute;
  top: 3rem;
  right: 0;
  /* bottom: 55vh; */
  /* width: 100%; */
  text-align: left;
  font-family: 'Piazzolla', serif;
  font-size: 1.3rem;
  color: whitesmoke;
  background-color: var(--orange);
  border-bottom-left-radius: 1.0rem;
  border-top-left-radius: 1.0rem;
  /* border-bottom-left-radius: 1.0rem; */
  padding: 0.5rem;
  cursor: pointer;
}
.carousel-demo-button-invert {
  color: var(--orange);
  background-color: black;
}
  
