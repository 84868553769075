#cover-image {
  height: 82vh;
  min-width: 100%;
  object-fit: cover;
  object-position: top;
  top: 0;
  margin-bottom: 0.5rem;
  border-bottom: 0.3rem solid black;
}
 /* Phone and tablet */
 @media only screen and (max-width: 992px){
  #cover-image {
    height: 60vh;
  }
}
#name {
    color: var(--orange);
    font-family: 'Piazzolla', serif;
    /* font-weight: bold; */
    font-size: 7vw;
    position: absolute;
    top: 10%;
    right: 3%;
    max-width: 40%;
    white-space: pre-wrap;
    text-align: center;

    text-shadow: 0.1vw 0.1vw 1px #e0e0e0,
    0.1vw 0.2vw 1px #e0e0e0,
    0.1vw 0.2vw 1px #e0e0e0,
    0.1vw 0.3vw 1px #e0e0e0;
  }
  #about-caption {
    position: absolute;
    bottom: 13%;
    left: 7%;
    width: 50%;
    padding: 0.6rem;
    border-radius: 10px;
    background-color: black;
    opacity: 0.85;
  }
  /* Phone and tablet */
  @media only screen and  (max-width: 992px) and  (min-height: 500px){
    #name {
      font-size: 10vw;
      right: 23%;
      width: 75%;
      white-space: pre-wrap;
      top: 34%;
    }
    #about-caption {
      position: relative;
      width: 95%;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 5vh;
      margin-bottom: -4vh;
      opacity: 0.9;
    }
  }
  
  #about-caption-text {
    font-size: 1.0rem;
    color: var(--orange);
  }
  #contact-information {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 1% 10%;
    /* color: whitesmoke; */
  }
  /* Phone */
  @media only screen and  (max-width: 992px){
    #contact-information {
      text-align: center;
      margin: auto;
    }
  }
.contact-text {
  font-size: 0.8rem;
  margin: auto;
  font-weight: 600;
  color: var(--green);
  overflow: hidden;
  margin-left: 1.5rem;
}  
@media only screen and (max-width: 992px){
  .contact-text {
    font-size: 0.7rem;
  }  
}
  