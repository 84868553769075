.line {
    position: static;
    right: 0;
    border-top: 0.15rem solid var(--green);
    width: 100vw;
  }
  #award-scroll-box{
    position: relative;
      display: block;
      white-space:nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      /* overflow: hidden; */
      text-align: center;
      width: 100%;
      margin: 0 0 0 0;
  }
  .award-arrow-Nav {
    display: table;
    position: absolute;
    text-align: center;
    z-index: 5;
    width: 7vw;
    height: 100%;
    border: none;
    border: none;
    color: whitesmoke;
    font-size: 5.0rem;
    background-color: black;
    opacity: 0.7;
    cursor: pointer;
  }
  /* Remove default Button Outlining */
  .award-arrow-Nav:active, .award-arrow-Nav:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
  .award-arrow-Nav:hover {
    opacity: 0.95;
    color: var(--orange);
  }
  .award-arrow-Left {
    left: 0;
    border-top-right-radius: 1.0rem;
    border-bottom-right-radius: 1.0rem;

  }
  .award-arrow-Right {
    right: 0;
    border-top-left-radius: 1.0rem;
    border-bottom-left-radius: 1.0rem;

  }
    
  
 #title {
    position: static;
    right: 0;
    border-bottom: 0.15rem solid var(--green);
    color: var(--orange);
    /* color: white; */
    text-align: center;
    padding: 0.5rem;
    margin: 0 0;
  }
  .award {
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-align: center;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    margin: 0 1.0rem;
    margin-top: 1.0rem;
    text-align: center;
    overflow: hidden;
    /* padding: auto; */
    width: 75vw;
    height: auto;
    /* min-width: 10.0rem;
    max-width: 15.0rem; */
  }
  @media only screen and (max-width: 600px){
    .award {
      width: 70vw;
    }
  }
  @media only screen and (min-width: 600px){
    .award {
      width: 35vw;
    }
  }
  @media only screen and (min-width: 992px){
    .award {
      width: 20vw;
    }
    .arrow-Nav {
      width: 7vw;
    }
  }
  @media only screen and (min-width: 1500px){
    .award {
      width: 15vw;
    }
  }


  .award:hover {
    /* position: absolute; */
      z-index: 2;
      /* zoom: 1.15; */
      transform: scale(1.25);
  }
  .award-image {
    position: relative;
    width: 80%;
    height: auto;
    border: 0.15rem outset var(--green);
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
    margin-top: 1.0rem;
  }
  .award-title {
    position: absolute;
    z-index: 3;
    left: 0;
    /* top: -1.0rem; */
    overflow: hidden;
    font-family: 'Piazzolla', serif;
    font-size: 1.3rem;
    color: var(--orange);
    background-color: black;
    border-bottom-right-radius: 0.8rem;
    padding: 0.35rem;
  }
  .award-text {
    position: relative;
    bottom: 1.3rem;
    margin-bottom: -1.3rem;
    width: 100%;
    white-space: pre-wrap;
    font-family: 'Raleway', sans-serif;
    font-size: 0.6rem;
    line-height: 0.8rem;
    letter-spacing: 0.15rem;
    text-decoration: none;
    color: white;
    padding: 0.35rem;
    border-radius: 8%;
    background-color: black;
    opacity: 0.9;
  }