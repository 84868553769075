#experience-section {
    /* margin-left: 20%; */
    margin: 0 1.0rem 0 0;
    width: 95%;
}

hr {
    position: relative;
    margin: 0 auto 1rem 0;
    right: 30%;
    border: 0;
    border-top: 0.25rem solid var(--green);
    /* width: 70%; */
  }
.link {
    /* text-decoration: underline var(--green) solid 0.05rem; */
    cursor: pointer;
}


.experience-note-title {
    margin: 0.25rem;
    font-family: 'Piazzolla', serif;
    font-size: 1.0rem;
    text-align: center;
    color: var(--green);
}
.experience-project-title {
    margin: 0.25rem;
    font-family: 'Piazzolla', serif;
    font-size: 1.1rem;
    text-align: center;
    color: var(--orange);
}
.experience-note-description {
    margin: 0;
    padding-bottom: 0.35rem;
    font-family: 'Raleway', sans-serif;
    font-size: 0.8rem;
    text-align: center;
    color: black;
}
.experience-project-description {
    margin: 0;
    padding-bottom: 0.35rem;
    font-family: 'Raleway', sans-serif;
    font-size: 0.8rem;
    text-align: center;
    color: white;
}
.experience-note-box {
    height: 100%;
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: whitesmoke;
    border-radius: 0.5rem;
    border: 0.10rem solid var(--green);
    padding: 0.4rem;
    margin: 0.3rem 0.5rem;
}
.experience-note-box:hover {
    transform: scale(1.25);
}
.experience-project-box {
    height: 100%;
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: black;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin: 0.3rem 0.5rem;
}
.experience-project-box:hover {
    transform: scale(1.25);
}
.experience-note-area {
    margin: 0.5rem auto 0.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: top;
    flex-wrap: wrap;
    justify-content: left;
    
}
.experience-project-area {
    margin: 0.5rem auto 0.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: top;
    flex-wrap: wrap;
    justify-content: left;
    
}
.experience-achievements-area {
    margin: 0 auto 0.5rem 0;
    display: block;    
}
.experience-achievements-list {
    margin: 0 auto 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: top;
    flex-wrap: wrap;
    justify-content: left;
    
}
.experience-summary {
    margin: 0.5rem;
    /* padding: 1.0rem; */
    /* background-color: black; */
    border-radius: 0.25rem;

    font-family: 'Raleway', sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0.09rem;
    line-height: 1.0rem;
    /* color: var(--orange); */
}
.experience-area {
    /* margin: 0% 5% 3% 5%; */
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: top;
    flex-wrap: wrap;
    justify-content: center;
    
}
.experience-logo {
    position: relative;
    width: 8vw;
    height: auto;
    margin: 1.0rem 2vw 0 3vw;
    /* width: 8%;
    left: 6%; */
    /* top: 2%; */
}
.experience-logo:hover {
    transform:scale(1.35);
}
/* phone  */
@media only screen and (max-width: 769px){
    #experience-section {
        margin: 3%;
    }
    .experience-project-box {
        width: 90%;
    }
    .experience-note-box {
        width: 90%;
    }

}