#formBox{
    /* border: 0.5rem ridge var(--orange); */
    border: 0.5rem groove var(--green);
    border-radius: 1.0rem;
    background-color: black;
    width: 100%;
    padding: 1.0rem;
    /* margin: 1.0rem ; */
}
#nameBox {
    font-family: 'Raleway', sans-serif;
    font-size: smaller;
    border: 0.15rem inset var(--green);
    width: 70%;
    margin: 3% 15% 3% 15%;
}
#nameBox:focus {
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.075), 0 0 12px rgba(255, 165, 0, 0.8);
}
#emailBox {
    font-family: 'Raleway', sans-serif;
    font-size: smaller;
    border: 0.15rem inset var(--green);
    
    width: 86%;
    margin: 3% 7% 3% 7%;
}
#emailBox:focus {
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.075), 0 0 12px rgba(255, 165, 0, 0.8);
}
#messageBox {
    font-family: 'Raleway', sans-serif;
    font-size: smaller;
    border: 0.15rem inset var(--green);
    min-height: 10.0rem;
}
#messageBox:focus {
    box-shadow: inset 0 2px 2px rgba(255, 255, 0, 0.075), 0 0 12px rgba(255, 165, 0, 0.8);
}
/* rgba(33, 66, 30, 0.6); green */
/* rgba(255, 165, 0, 0.6) orange  */
#sendButton {
    display: block;
    align-items: center;
    background-color: var(--orange);
    font-weight: 500;
    letter-spacing: 0.15rem;
    color: black;
    margin: 1.0rem auto;
}
#sendButton:hover {
    background-color: black;
    color: var(--orange);
    box-shadow: inset 0 3px 3px rgba(255, 255, 0, 0.075), 0 0 16px rgba(33, 66, 0, 1.0);
}
#reviewButton {
    display: block;
    align-items: center;
    border: none;
    /* background-color: black; */
    font-weight: 400;
    font-size: 0.7rem;
    letter-spacing: 0.15rem;
    /* color: whitesmoke; */
    padding: 0.25rem;
    margin: 0 auto;
    background-color: black;
    color: var(--orange);
    box-shadow: inset 0 3px 3px rgba(255, 255, 0, 0.075), 0 0 16px rgba(33, 66, 0, 1.0);
}
#reviewButton:hover {
    background-color: black;
    color: whitesmoke;
    box-shadow: inset 0 3px 3px rgba(255, 165, 0, 0.8), 0 0 16px rgba(255, 165, 0, 0.8);
}